import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Seo from "../components/common/seo"
import Layout from "../components/common/layout"
import Banner from "../components/common/banner"
import Professionals from "../components/common/professionals"
import About from "../components/common/about"
import Certifications from "../components/common/certifications"
import Numbers from "../components/common/numbers"
import bgImage from "../images/about/banner.png"

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query {
      api {
        professionals (sort: "position") {
          slug
          title
          introduction
          description
          content
          position
          image {
            url
            imageFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, aspectRatio: 0.8)
              }
            }
          }
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <Layout>
        <Seo title="Institucional" />
        <Banner
          title="Institucional"
          image={bgImage}
          links={[{ title: "Institucional", path: "/institucional" }]}
        />
        <About />
        <Professionals data={data.api.professionals} />
        <Numbers />
        <Certifications />
      </Layout>
    </React.Fragment>
  )
}

export default AboutPage
