import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { Parallax } from "react-parallax"

const BannerComponent = ({ title, image, links }) => {
  return (
    <div className="banner">
      <Parallax blur={3} bgImage={image} bgImageAlt="" strength={300}>
        <Container>
          <Row>
            <Col>
              <div className="title padding-top">
                <h1 className="mb-1">{title}</h1>
                <div className="separator mb-1"></div>
                <div className="breadcrumbs half-padding-bottom">
                  <Link to="/">Home</Link> <span className="sep-sm"> · </span>
                  {links.length > 0 &&
                    links.map((link, i) => {
                      return (
                        <React.Fragment key={i}>
                          <Link to={link.path}>{link.title}</Link>
                          {links[i + 1] ? (
                            <span className="sep-sm"> · </span>
                          ) : (
                            ""
                          )}
                        </React.Fragment>
                      )
                    })}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Parallax>
    </div>
  )
}

export default BannerComponent
